<template>
  <section
    :class="[
      'hero',
      {
        'hero--with-subheading': subheading,
        'hero--without-image': !$slots.image || !$slots.image.length,
        'hero--has-bgcolor': bgColor,
      },
    ]"
  >
    <component :is="headlineLevel" class="hero__heading headline1">{{ heading }}</component>
    <div v-if="subheading" class="hero__subheading">
      <p v-if="typeof subheading == 'string'">{{ typeof subheading == 'string' ? subheading : null }}</p>
      <slot name="subheading" />
    </div>

    <div
      v-if="$slots.image && $slots.image.length"
      :class="['hero__image', { 'hero__image--limit': limitMobileImageHeight }]"
    >
      <slot name="image" />
    </div>
    <div v-if="$slots.default && $slots.default.length" class="hero__text">
      <section><slot></slot></section>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    bgColor: { type: Boolean, required: false, default: true },
    heading: { type: String, required: true },
    subheading: { required: false, default: false },
    headlineLevel: { required: false, type: String, default: 'h1' },
    limitMobileImageHeight: { type: Boolean, required: false, default: true },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  width: 100%;

  &__image {
    box-sizing: border-box;
    display: flex;
    height: 300px;
    justify-content: center;
    order: 2;
    width: 100%;

    @media (min-width: 751px) {
      order: 3;
    }
    @media (max-width: 750px) {
      height: unset;
    }

    picture {
      max-width: 100%;
      width: 1328px;
    }
    height: auto;
    img {
      border-radius: 8px;
      margin-top: -100px;
      object-fit: contain;
      width: 100%;
      object-position: bottom;
      max-height: none;

      @media (min-width: 750px) {
        margin-top: -120px;
        max-width: 100%;
        max-height: none;
        object-fit: cover;
        width: 1328px;
      }
    }

    &--limit {
      img {
        max-height: none;
      }
    }
  }

  &__text {
    align-items: center;
    flex-direction: column;
    order: 4;
  }

  &__text,
  &__image {
    display: flex;
    padding: 0 24px;
  }

  &__text > section {
    padding-bottom: 100px;
    padding-top: 32px;
    @media (min-width: 750px) {
      padding-top: 60px;
    }

    h3 {
      text-align: left;
      width: 100%;
      font-size: 32px;
      margin-top: 0;
    }

    p,
    h3 {
      max-width: 800px;
    }
  }
  &__heading {
    display: flex;
  }
  &__heading,
  &__subheading {
    justify-content: center;
    flex-direction: column;
    padding: 0 24px;

    @media (min-width: 800px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 1328px;
      width: calc(100% - 48px);
    }
  }

  &__heading {
    margin-top: 0;
    margin-bottom: 150px;
  }

  &__subheading {
    order: 3;

    @media (min-width: 750px) {
      order: 2;
      padding-bottom: 150px;
    }

    p {
      font-size: 16px;
      font-weight: $fw-light;
      line-height: 1.25;
      margin-top: 20px;

      @media (min-width: 750px) {
        margin: 0;
      }
    }
  }

  &--has-bgcolor:not(.hero--without-image) {
    .hero__image,
    .hero__subheading,
    .hero__text {
      background-color: $c-secondary-background;
    }
    .hero__subheading {
      @media (min-width: 750px) {
        background-color: $c-primary-background;
      }
    }
  }

  &--with-subheading {
    .hero__heading {
      @media (min-width: 750px) {
        margin-bottom: 16px;
        padding-bottom: 0;
      }
    }
  }

  &--without-image {
    .hero__heading {
      margin-bottom: 60px;
    }
    .hero__subheading {
      padding-bottom: 0px;
    }
  }

  &.hero--without-image.hero--with-subheading {
    .hero__heading {
      margin-bottom: 0px;
    }
    .hero__subheading {
      padding-bottom: 60px;
    }
  }
}
</style>
