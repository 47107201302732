function toggleAttributes(el, value) {
  if (value) {
    el.setAttribute('inert', 'true');
    el.setAttribute('aria-hidden', 'true');
  } else {
    el.removeAttribute('inert');
    el.removeAttribute('aria-hidden');
  }
}

export default function handleBackgroundElements(elements = [], value = null) {
  document.querySelector('html').classList.toggle('screenreader-disable-scroll');

  if (elements.length) {
    elements = document.querySelectorAll(elements.join(','));
    elements.forEach((el) => toggleAttributes(el, value));
  }
}
