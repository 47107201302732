<template>
  <div class="two-col-content-block">
    <div v-if="$slots.image && $slots.image.length" class="two-col-content-block__image">
      <slot name="image"> </slot>
    </div>
    <div
      :class="[
        'two-col-content-block__text-container',
        { 'two-col-content-block__text-container--with-image': $slots.image && $slots.image.length },
        { 'two-col-content-block__text-container--secondaryBG': secondaryBG },
      ]"
    >
      <div
        :class="['two-col-content-block__text-wrapper', { 'two-col-content-block__no-top-margin': noTopMargin }]"
        v-if="$slots.default && $slots.default.length"
        :style="{ 'max-width': maxWidth + 'px' }"
      >
        <div>
          <component :is="headlineLevel" :class="['two-col-content-block__heading', headlineDisplayClass]">{{
            heading
          }}</component>
          <component
            v-if="headingLineTwo !== undefined"
            :is="headlineLevel"
            :class="['two-col-content-block__bonus-heading', headlineDisplayClass]"
            >{{ headingLineTwo }}</component
          >
        </div>
        <section class="two-col-content-block__text">
          <slot></slot>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: { type: String, required: true },
    headingLineTwo: { type: String, required: false },
    headlineDisplayClass: { type: String, required: false },
    headlineLevel: { type: String, required: false, default: 'h2' },
    secondaryBG: { type: Boolean, required: false, default: true },
    maxWidth: { type: Number, required: false, default: 1172 },
    fullwidth: { type: Boolean, required: false, default: false },
    noTopMargin: { type: Boolean, required: false, default: false },
  },
};
</script>

<style lang="scss" scoped>
.two-col-content-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  &__text-container {
    padding-bottom: 20px;
    padding-top: 50px;

    @media (min-width: 750px) {
      display: flex;
      width: 100%;
      padding-top: 0;
      padding-bottom: 95px;
    }

    &--secondaryBG {
      background-color: $c-secondary-background;
    }

    &--with-image {
      padding-top: 140px;
      @media (min-width: 750px) {
        padding-top: 140px;
      }
    }
  }

  &__text-wrapper {
    padding: 0 32px;
    @media (min-width: 750px) {
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      margin-top: 120px;
    }
  }

  &__no-top-margin {
    margin-top: 0;
  }

  &__text {
    max-width: 725px;
    p:first-of-type {
      @media (min-width: 750px) {
        margin-top: 0;
      }
    }
    strong {
      font-size: 16px;
      font-weight: 600;
      @media (min-width: 750px) {
        font-size: 19px;
      }
    }
    .fw-600 {
      font-weight: 600;
    }
    ul {
      list-style: none;
      padding-left: 0;
      li,
      .p {
        font-size: 16px;
        margin: 16px 0;
        @media (min-width: 750px) {
          font-size: 20px;
          margin: 16px 0;
        }
        ul {
          padding-left: 16px;
          @media (min-width: 750px) {
            padding-left: 60px;
          }
          li {
            list-style-type: disc;
          }
          @media (max-width: 750px) {
            li + li {
              margin-top: 0;
            }
            li:first-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .button {
      min-width: 288px;
      @media (max-width: 750px) {
        border-radius: 30px;
      }
    }
  }
  &__heading,
  &__bonus-heading {
    font-weight: $fw-regular;
    @media (min-width: 750px) {
      padding-right: 32px;
      min-width: 330px;
    }
    @media (min-width: 1020px) {
      padding-right: 100px;
      max-width: 325px;
    }
  }
  &__bonus-heading {
    padding-top: 15px;
  }
  .extra-wide {
    max-width: 350px;
    width: 350px;
  }
  &__image {
    max-width: 1328px;
    margin-bottom: -152px;
    padding: 34px 24px 0;
    z-index: 10;

    img {
      width: 100%;
      border-radius: 13px;
    }

    @media (max-width: 750px) {
      padding: 0 0 34px 0;
      width: 100%;
      img {
        border-radius: 0;
      }
    }
  }
}
</style>
