<template>
  <span>
    <button @click="openToast" class="reference-button" :aria-label="buttonLabel">
      <sup :class="['ref-link', { 'ref-link--white-bg': isWhite }]">
        {{ num }}
      </sup>
    </button>
    <div
      v-if="open"
      :id="referenceID"
      class="reference"
      :class="{ mobile: isMobile }"
      role="dialog"
      :aria-label="dialogLabel"
    >
      <button @click="closeToast" class="close" aria-label="close dialog" style="cursor: pointer" ref="close">
        <SearchCloseIcon class="closeIcon" />
      </button>
      <div class="wrapper">
        <small>{{ num }}</small>
        <slot />
      </div>
    </div>
  </span>
</template>

<script>
import handleBackgroundElements from '@/helpers/handleBackgroundElements';
import SearchCloseIcon from '@/assets/images/Global/searchCloseIcon.svg';

export default {
  name: 'Reference-Link',
  components: { SearchCloseIcon },
  props: {
    num: null,
    isWhite: { type: Boolean },
  },
  data() {
    return {
      priorFocus: null,
      open: false,
      buttonLabel: `Disclosure ${this.num}, this opens a modal.`,
      dialogLabel: `Reference ${this.num} modal.`,
      referenceID: Math.random().toString(36).substring(2, 15),
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    currentRefID() {
      return this.$store.state.currentRefID;
    },
  },
  methods: {
    openToast() {
      handleBackgroundElements(
        [
          'header, main a, main button, main input, main select, main textarea, footer, div.right-nav, a.logo-container, a.sr-only, img, h2, article-body mobile',
        ],
        'true'
      );
      this.$store.commit('setCurrentRefID', this.referenceID);
      this.open = true;
      this.priorFocus = document.activeElement;
      this.$nextTick(() => {
        const focusClose = this.$refs.close;
        focusClose.focus();
      });
    },
    closeToast() {
      handleBackgroundElements([
        'header, main a, main button, main input, main select, main textarea, footer, div.right-nav, a.logo-container, a.sr-only, img, h2, article-body mobile',
      ]);
      this.open = false;
      this.priorFocus.focus();
    },
  },
  watch: {
    currentRefID(newValue) {
      if (newValue !== this.referenceID) {
        this.open = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.closeIcon {
  width: 10px;
}
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}
a,
router-link,
.link {
  color: $c-primary-background;
  &:hover {
    color: $c-highlights;
  }
}
.reference-button {
  height: 15px;
  width: 15px;
  .ref-link {
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    background-color: $c-primary;
    border-radius: 50%;
    font-size: 9px;
    color: $c-primary-background;
    height: 15px;
    width: 15px;
    padding: 0;
    font-weight: $fw-medium;
    line-height: 1rem;
    &--white-bg {
      background-color: #fff;
      color: #011352;
      line-height: 1.6em;
    }
  }
}
.reference {
  z-index: 1000;
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  max-height: 500px;
  background-color: #262626;
  color: $c-primary-background;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-indent: 0;
  font-weight: $fw-regular;
  font-size: 1rem;
  max-height: 100%;
  overflow: auto;
  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
  &.mobile {
    width: 100%;
    .wrapper {
      padding: 0px 20px;
    }
  }
  &:not(.mobile) {
    width: 80%;
    .wrapper {
      margin: 0px 100px;
      margin-bottom: 40px;
    }
  }
  .close {
    align-self: flex-end;
    padding: 10px 20px;
  }
  .wrapper {
    small {
      margin-right: 4px;
      position: relative;
      bottom: 5px;
      font-weight: normal;
    }
  }
}
</style>
