<template>
  <div class="accordion" :id="accordionID + '-accordion'">
    <ul class="accordion-list" :aria-labelledby="headerId">
      <slot></slot>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    headerId: { type: String, required: false },
  },
  data() {
    return {
      accordionID: Math.random().toString(36).substring(2, 15),
      current: null,
    };
  },
  mounted() {
    this.$root.$on('toggleAccordion', (data) => {
      this.current = data.uniqueID;
      this.$root.$emit('refreshAccordion', data);
    });
  },
};
</script>
<style scoped lang="scss">
.accordion-list {
  list-style: none;
  padding: 0;
}
</style>
